import React, {useEffect} from 'react'
import './HeaderLoginPanel.css'
import {connect} from 'react-redux'
import {getAccount} from '../reducers/appReducer'

const HeaderLoginPanel = (props) => {
    useEffect(() => {
        if (props.vizonator) {
            if (!props.account) {
//                props.getAccount()
            }
        }
    }, [props.vizonator])

    return (
        <div id="header-login-panel">
            <div id="header-text">
                {props.vizonator ?
                    (props.account ? props.account.login : <a href="#" onClick={props.getAccount}>Login</a>) :
                    <><a href="https://viz.world/vizonator/" target="_blank" rel="noreferrer">Vizonator</a> not installed</>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    account: state.app.account,
    vizonator: state.app.vizonator,
})
const mapDispatchToProps = {
    getAccount,
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLoginPanel)
