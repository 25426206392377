import './App.css'
import FooterColorPalette from './components/FooterColorPalette'
import Grid from './components/Grid'
import {useEffect} from 'react'
import HeaderLoginPanel from './components/HeaderLoginPanel'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {initialize, setCanvas, setNewColor} from './reducers/appReducer'
import FooterGridPanel from './components/FooterGridPanel'
import Canvas from "./components/Canvas";
import PaintDialog from "./components/PaintDialog";

function App(props) {
    useEffect(() => {
        if (!props.initialized) {
            props.initialize()
        }
    }, [props.initialized])
    useEffect(() => {
        if (props.socket) {
            props.socket.on('initialize', (data) => {
                props.setCanvas(data)
            })
            props.socket.on('newColor', (data) => {
                props.setNewColor(data)
            })
        }
    }, [props.socket])

    if (!props.initialized) {
        return <div className="App">
            <div>Loading...</div>
        </div>
    }
    return <div className="App">
        <Canvas/>
        <PaintDialog/>
        <HeaderLoginPanel/>
        <FooterColorPalette/>
        <FooterGridPanel/>
    </div>
}

const mapStateToProps = (state) => ({
    initialized: state.app.initialized,
    socket: state.app.socket,
})
const mapDispatchToProps = {
    initialize,
    setCanvas,
    setNewColor,
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(App)