import React from 'react'
import './GridItem.css'

const GridItem = (props) => {
    return (
        <div className="GridItem"
             style={{
                 height: `${props.gridSize}px`,
                 width: `${props.gridSize}px`,
                 borderColor: props.color,
                 backgroundColor: props.color}}
             onClick={props.handleClick}
        >
        </div>
    )
}

export default GridItem
