import React from 'react'
import {connect} from 'react-redux'
import {setDisplayPaintDialog} from "../reducers/appReducer";

const PaintDialog = (props) => {
    const sendPixel = () => {
        props.setDisplayPaintDialog(false)
        if (!window.vizonator || !props.selectedPixel) {
            return
        }
        const color = props.selectedColor
        const {x, y} = props.selectedPixel
        let data = JSON.stringify({x, y, color})
        window.vizonator.custom({authority: 'regular', id: 'color.place', json: data}, (error, result) => {
            if (error) {
                console.log(result)
            }
        })
    }

    return <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        visibility: props.displayPaintDialog ? 'visible' : 'hidden',
        background: '#888888',
        border: '2px solid #ffffff',
        padding: '5px',
    }}>
        Pixel position: {props.selectedPixel && `${props.selectedPixel.x}, ${props.selectedPixel.y}`}<br/>
        New color: <span style={{color: props.selectedColor}}>{props.selectedColor}</span><br/>
        <span style={{float: 'left', color: 'black', cursor: 'pointer'}} onClick={sendPixel}>Paint</span>
        <span style={{float: 'right', color: 'black', cursor: 'pointer'}} onClick={() => props.setDisplayPaintDialog(false)}>Close</span>
    </div>

}

const mapStateToProps = (state) => ({
    displayPaintDialog: state.app.displayPaintDialog,
    selectedColor: state.app.selectedColor,
    selectedPixel: state.app.selectedPixel,
})
const mapDispatchToProps = {
    setDisplayPaintDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaintDialog)