import React from 'react'
import './FooterGridPanel.css'
import {connect} from 'react-redux'
import {setGridPos, setGridSize} from '../reducers/appReducer'

const FooterGridPanel = (props) => {
    return (
        <div id="footer-grid-panel">
            <img src={'left.png'} className={'zoom-img'} onClick={() => props.setGridPos({x: props.gridPos.x - 10, y: props.gridPos.y})}/>
            <img src={'up.png'} className={'zoom-img'} onClick={() => props.setGridPos({x: props.gridPos.x, y: props.gridPos.y - 10})}/>
            <img src={'down.png'} className={'zoom-img'} onClick={() => props.setGridPos({x: props.gridPos.x, y: props.gridPos.y + 10})}/>
            <img src={'right.png'} className={'zoom-img'} onClick={() => props.setGridPos({x: props.gridPos.x + 10, y: props.gridPos.y})}/>
            <img src={'zoom-in.png'} className={'zoom-img'} onClick={() => props.setGridSize(props.gridSize + 1)}/>
            <img src={'zoom-out.png'} className={'zoom-img'} onClick={() => props.setGridSize(props.gridSize - 1)}/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    gridPos: state.app.gridPos,
    gridSize: state.app.gridSize,
})
const mapDispatchToProps = {
    setGridPos,
    setGridSize,
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterGridPanel)
