import React from 'react'
import './FooterColorPalette.css'
import {connect} from 'react-redux'
import {setSelectedColor} from '../reducers/appReducer'

const colors = [
    '#ffffff',
    '#e4e4e4',
    '#888888',
    '#222222',
    '#ffa7d1',
    '#e50000',
    '#e59500',
    '#a06a42',
    '#e5d900',
    '#94e044',
    '#02be01',
    '#00d3dd',
    '#0083c7',
    '#0000ea',
    '#cf6ee4',
    '#820080',
]

const FooterColorPalette = (props) => {
    if (!props.selectedColor || colors.indexOf(props.selectedColor) < 0) {
        props.setSelectedColor(colors[0])
    }

    return (
        <div id="footer-bar">
            <div id="color-palette">
                {colors.map((color) => (
                    <ColorOption
                        key={color}
                        color={color}
                        isSelected={color === props.selectedColor}
                        setColor={() => props.setSelectedColor(color)}
                    />)
                )}
            </div>
        </div>
    )
}

const ColorOption = (props) => {
    let classNames = "color-option"
    if (props.isSelected) {
        classNames += " selected-color-option"
    }
    return <div
        className={classNames}
        style={{backgroundColor: props.color}}
        onClick={props.setColor}
    />
}

const mapStateToProps = (state) => ({
    selectedColor: state.app.selectedColor,
})
const mapDispatchToProps = {
    setSelectedColor,
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterColorPalette)