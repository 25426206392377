import React from 'react'
import GridItem from './GridItem'
import './Grid.css'
import {connect} from 'react-redux'

const Grid = (props) => {
    if (!props.canvas) {
        return <div>"Loading..."</div>
    }

    const clickHandler = (x, y) => {
        if (!window.vizonator) {
            return
        }
        const color = props.selectedColor
        let data = JSON.stringify({x, y, color})
        window.vizonator.custom({authority: 'regular', id: 'color.place', json: data}, (error, result) => {
            if (error) {
                console.log(result)
            }
        })
    }

    const gridItems = []
    for (let rowIndex = 0; rowIndex < props.canvas.length; rowIndex++) {
        gridItems[rowIndex] = []
        for (let columnIndex = 0; columnIndex < props.canvas[rowIndex].length; columnIndex++) {
            gridItems[rowIndex].push(
                <GridItem
                    key={`item${rowIndex}_${columnIndex}`}
                    color={props.canvas[rowIndex][columnIndex]}
                    gridSize={props.gridSize}
                    handleClick={() => {clickHandler(columnIndex, rowIndex)}}
                />,
            )
        }
    }
    return <div className={'Grid'} style={{marginTop: `${props.gridPos.y}px`, marginLeft: `${props.gridPos.x}px`}}>
        {gridItems.map(rowItem => {
            return <div className="GridRow">{rowItem}</div>
        })}
    </div>
}

const mapStateToProps = (state) => ({
    canvas: state.app.canvas,
    gridPos: state.app.gridPos,
    gridSize: state.app.gridSize,
    selectedColor: state.app.selectedColor,
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Grid)