import React, {memo} from 'react'
import {FixedSizeGrid as Grid, areEqual} from 'react-window'
import {connect} from 'react-redux'
import AutoSizer from 'react-virtualized-auto-sizer'
import memoize from 'memoize-one'
import './Grid.css'
import './GridItem.css'
import {setDisplayPaintDialog, setSelectedPixel} from "../reducers/appReducer";

const Cell = memo(({data, columnIndex, rowIndex, style}) => {
    const {canvas, clickHandler} = data
    const item = canvas[rowIndex][columnIndex];
    return <div
        className="GridItem"
        style={{
            ...style,
            borderColor: item,
            backgroundColor: item,
        }}
        onClick={() => clickHandler(columnIndex, rowIndex)}
    >
    </div>
}, areEqual)

const createItemData = memoize((canvas, clickHandler) => ({
    canvas,
    clickHandler,
}))

const Canvas = (props) => {
    if (!props.canvas) {
        return <div>"Loading..."</div>
    }

    const clickHandler = (x, y) => {
        props.setDisplayPaintDialog(true)
        props.setSelectedPixel({x, y})
    }

    const itemData = createItemData(props.canvas, clickHandler)

    return <AutoSizer>
        {({height, width}) => (
            <Grid
                className={'Grid'}
                columnCount={props.canvas.length}
                columnWidth={props.gridSize}
                height={height - 110}
                rowCount={props.canvas[0].length}
                rowHeight={props.gridSize}
                width={width}
                itemData={itemData}
            >
                {Cell}
            </Grid>
        )}
    </AutoSizer>

}

const mapStateToProps = (state) => ({
    canvas: state.app.canvas,
    gridPos: state.app.gridPos,
    gridSize: state.app.gridSize,
})
const mapDispatchToProps = {
    setDisplayPaintDialog,
    setSelectedPixel,
}

export default connect(mapStateToProps, mapDispatchToProps)(Canvas)