import io from 'socket.io-client'
import {SERVER_URL} from '../utils/constant'

const SET_ACCOUNT = 'SET_ACCOUNT'
const SET_CANVAS = 'SET_CANVAS'
const SET_DISPLAY_PAINT_DIALOG = 'SET_DISPLAY_PAINT_DIALOG'
const SET_GRID_POS = 'SET_GRID_POS'
const SET_GRID_SIZE = 'SET_GRID_SIZE'
const SET_INITIALIZE = 'SET_INITIALIZE'
const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
const SET_MODAL_ERROR = 'SET_MODAL_ERROR'
const SET_NEW_COLOR = 'SET_NEW_COLOR'
const SET_SELECTED_COLOR = 'SET_SELECTED_COLOR'
const SET_SELECTED_PIXEL = 'SET_SELECTED_PIXEL'
const SET_SOCKET = 'SET_SOCKET'
const SET_VIZONATOR = 'SET_VIZONATOR'

const initialState = {
    account: null,
    canvas: null,
    displayPaintDialog: false,
    gridPos: {x: 0, y: 0},
    gridSize: 7,
    initialized: false,
    loadingStatus: false,
    loadingText: null,
    modalErrorStatus: false,
    modalErrorText: null,
    selectedColor: null,
    selectedPixel: null,
    socket: null,
    vizonator: false,
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCOUNT: {
            let newState = {...state}
            newState.account = action.account
            return newState
        }
        case SET_CANVAS: {
            let newState = {...state}
            newState.canvas = action.canvas
            return newState
        }
        case SET_DISPLAY_PAINT_DIALOG: {
            let newState = {...state}
            newState.displayPaintDialog = action.status
            return newState
        }
        case SET_GRID_POS: {
            let newState = {...state}
            newState.gridPos = action.pos
            return newState
        }
        case SET_GRID_SIZE: {
            let newState = {...state}
            let size = action.size > 15 ? 15 : (action.size < 0 ? 0 : action.size)
            newState.gridSize = size
            return newState
        }
        case SET_INITIALIZE: {
            let newState = {...state}
            newState.initialized = true
            return newState
        }
        case SET_LOADING_STATUS: {
            return {
                ...state,
                loadingStatus: action.status,
                loadingText: action.text
            }
        }
        case SET_MODAL_ERROR: {
            let newState = {...state}
            newState.modalErrorStatus = action.status
            newState.modalErrorText = action.error
            return newState
        }
        case SET_NEW_COLOR: {
            let newState = {...state}
            newState.canvas = [...state.canvas]
            newState.canvas[action.y][action.x] = action.color
            return newState
        }
        case SET_SELECTED_COLOR: {
            let newState = {...state}
            newState.selectedColor = action.color
            return newState
        }
        case SET_SELECTED_PIXEL: {
            let newState = {...state}
            newState.selectedPixel = action.pixel
            return newState
        }
        case SET_SOCKET: {
            let newState = {...state}
            newState.socket = action.socket
            return newState
        }
        case SET_VIZONATOR: {
            let newState = {...state}
            newState.vizonator = action.status
            return newState
        }
        default:
            return state
    }
}

export const getAccount = () => {
    return async (dispatch) => {
        if (!window.vizonator) {
            dispatch(setAccount(null))
        } else {
            window.vizonator.get_account((error, result) => {
                if (!error) {
                    dispatch(setAccount(result))
                }
            })
        }
    }
}
export const initialize = () => {
    return async (dispatch) => {
        waitVizonator(dispatch)
        await Promise.all([dispatch(setSocket(io(SERVER_URL)))])
        dispatch(setInitialize())
    }
}
export const setAccount = (account) => ({
    type: SET_ACCOUNT,
    account: account
})
export const setCanvas = (canvas) => ({
    type: SET_CANVAS,
    canvas: canvas
})
export const setDisplayPaintDialog = (status) => ({
    type: SET_DISPLAY_PAINT_DIALOG,
    status: status
})
export const setGridPos = (pos) => ({
    type: SET_GRID_POS,
    pos: pos
})
export const setGridSize = (size) => ({
    type: SET_GRID_SIZE,
    size: size
})
export const setInitialize = () => ({
    type: SET_INITIALIZE
})
export const setLoadingStatus = (status, text) => ({
    type: SET_LOADING_STATUS,
    status: status,
    text: text
})
export const setModalError = (status, error) => ({
    type: SET_MODAL_ERROR,
    error: error,
    status: status
})
export const setNewColor = (data) => ({
    type: SET_NEW_COLOR,
    x: data.x,
    y: data.y,
    color: data.color,
})
export const setVizonator = (status) => ({
    type: SET_VIZONATOR,
    status: status
})
export const setSelectedColor = (color) => ({
    type: SET_SELECTED_COLOR,
    color: color
})
export const setSelectedPixel = (pixel) => ({
    type: SET_SELECTED_PIXEL,
    pixel: pixel
})
export const setSocket = (socket) => ({
    type: SET_SOCKET,
    socket: socket
})
const waitVizonator = (dispatch) => {
    if (window.vizonator) {
        dispatch(setVizonator(true))
    } else {
        setTimeout(() => waitVizonator(dispatch), 1000)
    }
}

export default appReducer